import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import DefaultLink from '../components/DefaultLink'
import ImageCaption from '../components/ImageCaption'
import Layout from '../components/Layout'
import ProjectHeroSection from '../components/ProjectHeroSection'
import DifferenceObject from '../components/DifferenceObject'
import device from '../lib/mediaQueries'
import * as colors from '../lib/colors'

const PaddedLayout = styled.div`
  padding: 0px 10% 48px 10%;
  /* text-align: justify; */

  ${device.laptop} {
    padding: 48px 25%;
  }
`

const Image = styled.img`
  position: relative;
  width: 120%;
  left: -10%;
  z-index: 100;
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 0px;
  overflow: hidden;
`

const components = {
  ImageCaption,
  wrapper: PaddedLayout,
  a: DefaultLink,
  img: Image,
}
const WorkTemplate = ({ data: { mdx } }) => {
  const {
    scheme,
    title,
    featuredImage,
    name,
    description,
    url,
  } = mdx.frontmatter
  const colorScheme = colors[scheme] || colors.main
  return (
    <Layout
      theme={colorScheme}
      title={name}
      description={title}
      image={featuredImage.childImageSharp.fixed.src}
    >
      <ProjectHeroSection
        title={title}
        name={name}
        image={featuredImage}
        url={url}
        description={description}
      />
      <MDXProvider components={components}>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </MDXProvider>
      <DifferenceObject maxLeft={40} maxTop={8} />
    </Layout>
  )
}

WorkTemplate.propTypes = {
  data: PropTypes.shape,
}

export default WorkTemplate

export const pageQuery = graphql`
  query($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        name
        title
        description
        url
        scheme
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
            fixed {
              src
            }
          }
        }
      }
    }
  }
`
