import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Img from 'gatsby-image'
import device from '../lib/mediaQueries'

const MainContainer = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;

  ${device.laptop} {
    flex-direction: row;
  }
`

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;
  margin-bottom: 10px;
  pointer-events: none;
  border-radius: 4px;
  overflow: hidden;

  ${device.laptop} {
    width: 45%;
    margin-bottom: 0px;
  }
`
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  ${device.laptop} {
    position: inherit;
    width: 55%;
    max-width: 55%;
    margin-bottom: 0px;
  }
`
const TitleCenterContainer = styled.div`
  max-width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${device.laptop} {
    max-width: 70%;
  }
`
const Title = styled.h1`
  color: ${(props) => props.theme.main};
  margin-top: 8px;
  margin-bottom: 16px;
`

const ArrowLine = styled.path`
  ${device.laptop} {
    opacity: 0;
  }
  opacity: 1;
  transition: opacity 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
`
const ArrowTip = styled.path`
  ${device.laptop} {
    transform: none;
  }
  transform: translateX(4px);
  transition: transform 250ms cubic-bezier(0.215, 0.61, 0.355, 1);
`
const Name = styled.a`
  color: ${(props) => props.theme.highlight};
  font-weight: bold;
  text-decoration: none;
  font-size: 18px;
  align-self: flex-start;

  svg {
    stroke-width: 2;
    fill: none;
    stroke: ${(props) => props.theme.highlight};
    margin-left: 4px;
  }

  &:hover ${ArrowTip} {
    transform: translateX(5px);
  }

  &:hover ${ArrowLine} {
    opacity: 1;
  }
`
const Description = styled.span``

const ProjectHeroSection = ({ name, title, description, image, url }) => (
  <MainContainer>
    <TitleContainer>
      <TitleCenterContainer>
        <Name href={url}>
          {name}
          {!!url && (
            <svg width="12" height="10" viewBox="0 0 10 10" aria-hidden="true">
              <g fillRule="evenodd">
                <ArrowLine d="M0 5h9"></ArrowLine>
                <ArrowTip d="M1 1l4 4-4 4"></ArrowTip>
              </g>
            </svg>
          )}
        </Name>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TitleCenterContainer>
    </TitleContainer>
    <ImageContainer>
      <Img fluid={image.childImageSharp.fluid} />
    </ImageContainer>
  </MainContainer>
)

ProjectHeroSection.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.any.isRequired,
  url: PropTypes.string,
}

export default ProjectHeroSection
