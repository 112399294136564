import styled from 'styled-components'

const ImageCaption = styled.div`
  margin-bottom: 24px;
  margin-top: -5px;
  padding: 0px 10%;
  color: ${(props) => props.theme.main};
  opacity: 0.7;
  font-size: 13px;
  text-align: center;
`

export default ImageCaption
